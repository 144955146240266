<template>
    <div id="app">
        <router-view />
    </div>
</template>

<script>
    export default {
        name: 'App',
    }
</script>


<style lang="less">
    @import url("./assets/css/base.less");
    @import url("./assets/css/style.less");

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        color: #2c3e50;
        font-size: 12px;
    }



    .el-switch__core {
        border-color: #666 !important;
    }

    .el-switch__core .el-switch.is-checked {
        background-color: rgba(25, 90, 223, .2) !important;
        border-color: #195adf !important;

    }

    .el-pagination button:disabled,
    .el-pager li {
        background: none !important;
        color: #fff;
    }

    .el-pagination .btn-next,
    .el-pagination .btn-prev {
        background: none !important;
        color: #999 !important;
    }

    .el-pager li.btn-quicknext,
    .el-pager li.btn-quickprev {
        color: #999 !important;
    }

    .el-input__inner {
        background-color: transparent !important;
        color: #fff;
    }

    :root {
        --el-border-color-base: #333 !important;
    }

    .el-autocomplete {
        width: 100% !important;
    }

    .el-dialog {
        --el-dialog-background-color: #111214 !important;
    }

    .el-date-range-picker__header div {
        margin: 0 !important;
        line-height: 50px;
    }
</style>