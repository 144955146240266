import { createRouter, createWebHistory } from 'vue-router'
const routes = [
    {
        path: '/',
        name: 'home',
        meta: {
            title: '华夏延误预测-首页',
            keepAlive: true
        },
        component: () => import('@/views/home/index.vue')
    },
    {
        path: "/notFound",
        name: "notFound",
        component: () => import('@/views/home/404.vue')
    }, {
        path: "/:catchAll(.*)", // 此处需特别注意置于最底部
        redirect: "/notFound"
    }

]


const router = createRouter({
    history: createWebHistory(), // hash模式：createWebHashHistory，history模式：createWebHistory
    routes
})
export default router