import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import { createPinia } from 'pinia'

const app = createApp(App);

app.use(router).mount('#app')
app.use(createPinia())
//初始化数据
document.title = '华夏延误预测'
router.beforeEach((to, from, next) => {
    if (to.meta.title) {
        document.title = to.meta.title + '-华夏延误预测'
    }
    next()

})